exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-docs-tsx-content-file-path-content-bootstrap-pagination-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/bootstrap-pagination/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-bootstrap-pagination-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-custom-styled-pagination-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/custom-styled-pagination/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-custom-styled-pagination-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-faq-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/faq/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-faq-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-home-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/home/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-home-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-installation-compatibility-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/installation-compatibility/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-installation-compatibility-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-migration-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/migration/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-migration-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-class-name-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/classNameProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-class-name-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-common-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/commonProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-common-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-extra-class-name-prop-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/extraClassNameProp.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-extra-class-name-prop-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-index-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-label-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/labelProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-label-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-legacy-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/legacyProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-legacy-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-link-href-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/linkHrefProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-link-href-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-narrow-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/narrowProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-narrow-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-props-render-nav-props-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/props/renderNavProps.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-props-render-nav-props-md" */),
  "component---src-templates-docs-tsx-content-file-path-content-themes-index-md": () => import("./../../../src/templates/docs.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/themes/index.md" /* webpackChunkName: "component---src-templates-docs-tsx-content-file-path-content-themes-index-md" */),
  "component---src-templates-standalone-tsx-content-file-path-content-live-demo-index-md": () => import("./../../../src/templates/standalone.tsx?__contentFilePath=/home/runner/work/react-responsive-pagination/react-responsive-pagination/packages/docs/content/live-demo/index.md" /* webpackChunkName: "component---src-templates-standalone-tsx-content-file-path-content-live-demo-index-md" */)
}

